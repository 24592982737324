import React, {useState, Fragment} from 'react';
import { SearchGolfTermsButton } from './SearchGolfTermsButton';
import '../assets/css/App.css';
import '../assets/css/HeroSection.css';

function HeroSection() {
   
    return (
        <div className='hero-container'>
            {/*<video src="../assets/videos/viedo-1.mp4" autoPlay loop muted />*/}                        
            <div className='input-areas' id='headerArea'>                   
                <h1 className='hero-container-title'>Allt du velat veta om golf</h1>            
            </div>                              
             <SearchGolfTermsButton />            
        </div>
    )
}

export default HeroSection
