import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import '../assets/css/Navbar.css';
import axios from 'axios';

export default function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [menuItems, setMenuItems] = useState([]);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    const getMenu = () => {
        try {    
            axios.get('https://golffakta-strapi.ettkontor.se/main-menu')
              .then(response => {                       
                setMenuItems(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });       
        } catch (error) {        
          this.setState({ error, loading: false });
        }  
    }

    useEffect(()=> {
         getMenu();
        showButton();
    },[])

    window.addEventListener('resize',showButton);

    return (        
        <>                  
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src='/images/golffakta-icon.png' className="logo" alt="Logo" />
                    </Link>  
                    <div className='menu-icon' onClick={handleClick} >
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>     
                    <ul className={click ? 'nav-menu active':'nav-menu'}>
                    {menuItems.map((menuItem, index) => (
                        <div key={index}>
                            <li className='nav-item'>
                                <Link to={menuItem.url} className='nav-links' onClick={closeMobileMenu}>
                                    {menuItem.label}
                                </Link>
                            </li>                            
                        </div>
                    ))}                                                                          
                    </ul>                              
                </div>
            </nav>
        </>
    )
}
