import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import {BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  withRouter} from 'react-router-dom';
import './assets/css/App.css'; 
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Articles from './components/pages/Articles';
import Infopages from './components/pages/Infopages';
import Golfterms from './components/pages/Golfterms';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)


function App() {
  return (
    <>
    <Router>
      <ScrollToTop>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/articles/:id" component={Articles} />
        <Route path="/infopages/:id" component={Infopages} />
        <Route path="/golfterms" component={Golfterms} />
        <Route path="/services" component={Services} />        
        <Route path="/products" component={Products} />
        <Route path="/sign-up" component={SignUp} />
      </Switch>
      <Footer />
      </ScrollToTop>
    </Router>
    </>
  );
}

export default App;
