//rfce
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/Footer.css';
import { Button } from './Button';
import axios from 'axios';

function Footer() {
  const [click, setClick] = useState(false);
  const [menuLeftItems, setMenuLeftItems] = useState([]);
  const [menuMiddleItems, setMenuMiddleItems] = useState([]);
  const [menuRightItems, setMenuRightItems] = useState([]);

  const closeMobileMenu = () => setClick(false);

  const getMenu = () => {
    try {    
        axios.get('https://golffakta-strapi.ettkontor.se/footer-menu')
          .then(response => {                       
            setMenuLeftItems(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });       
    } catch (error) {        
      this.setState({ error, loading: false });
    }  
    try {    
      axios.get('https://golffakta-strapi.ettkontor.se/footer-menu-middle')
        .then(response => {                       
          setMenuMiddleItems(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });       
    } catch (error) {        
      this.setState({ error, loading: false });
    }  
    try {    
      axios.get('https://golffakta-strapi.ettkontor.se/footer-menu-right')
        .then(response => {                       
          setMenuRightItems(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });       
    } catch (error) {        
      this.setState({ error, loading: false });
    }        
  }
  useEffect(()=> {
    getMenu();  
  },[])
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Följ med på vår resa i golfens värld
        </p>
     
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Din epost'
            />
            <Button buttonStyle='btn--light' >Prenumerera</Button>
          </form>
        </div>
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>golffakta.se</h2>
            {menuLeftItems.map((menuItem, index) => (
              (menuItem.newwindow) ? 
                <a href={menuItem.url} target='_blank' rel="noreferrer">{menuItem.label}</a>                 
              :
              <Link key={index} to={menuItem.url} className='footer-nav-links' onClick={closeMobileMenu}>
                {menuItem.label}
              </Link>                
            ))}            
          </div>
        </div>     
        <div className='footer-link-wrapper'>          
          <div className='footer-link-items'>
            <h2>Följ oss</h2>
            {menuMiddleItems.map((menuItem, index) => (   
              (menuItem.newwindow) ? 
              <a key={index} href={menuItem.url} target='_blank' rel="noreferrer">{menuItem.label}</a>              
              : 
              <a key={index} href={menuItem.url}>{menuItem.label}</a>                                   
            ))}            
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Det finstilta</h2>
            {menuRightItems.map((menuItem, index) => (
              (menuItem.newwindow) ? 
              <a key={index} href={menuItem.url} target='_blank' rel="noreferrer">{menuItem.label}</a>                 
              :
              <Link key={index} to={menuItem.url} className='footer-nav-links' onClick={closeMobileMenu}>
              {menuItem.label}
              </Link>                       
            ))}            
            </div>
          </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <small className='website-rights'>Copyright golffakta.se 2021</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;