import React, {useState, useEffect,Fragment} from 'react';
import axios from 'axios';
import MarkdownView from 'react-showdown';
import '../../assets/css/App.css';
import '../../assets/css/Golfterms.css';
import { SearchGolfTermsButton } from '../SearchGolfTermsButton';
//import { HashLink } from 'react-router-hash-link';


export default function Golfterms() {
    const [golfterms, setGolfterms] = useState([]);

    const getGolfterms = async () => {
        try {                
            axios.get('https://golffakta-strapi.ettkontor.se/golfterms?_sort=title&_limit=-1')
              .then(response => {                       
                setGolfterms(response.data);                
              })
              .catch(function (error) {
                console.log(error);
                setGolfterms({
                    id:0,
                    title:'Sorry. golfterm not found'
                });    
              });       
        } catch (error) {        
          this.setState({ error, loading: false });
        }  
    }
    
    useEffect(()=> {        
        getGolfterms();            
    },[]);

    return (
        <>
      <div className="golfterms">              
            <div className="golfterms__container">
                <div className="golfterms__wrapper">    
                <h1>Golftermer</h1>          
                <div className='input-areas'>                
                <SearchGolfTermsButton />
                </div>               
                        {golfterms.map((golfterm, index) => (   
                            <div key={index}>                  
                            <div className="golfterms__content">   
                                <h2>{golfterm.title}</h2>
                                <div className="content">
                                ({golfterm.title_en})
                                <MarkdownView
                                    markdown={golfterm.content}
                                    options={{ tables: true, emoji: true, simpleLineBreaks: true }}
                                />                            
                                </div>
                             </div>
                            </div>
                        ))}
                    </div> 
            </div>
        </div>
        </>
    )
}