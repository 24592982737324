/* https://ericgio.github.io/react-bootstrap-typeahead/  */
import React, {useState, Fragment} from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import '../assets/css/Button.css';
import '../assets/css/typeahead.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import search_icon from '../assets/images/search_icon.png'; // with import

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

const SEARCH_URI = 'https://golffakta-strapi.ettkontor.se/golfterms';
const SEARCH_URI_test = 'https://api.github.com/search/users';

export const SearchGolfTermsButton = ({
    children, 
    type, 
    onClick, 
    buttonStyle, 
    buttonSize
}) => {
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
    
    const checkButtonStyle = STYLES.includes(buttonStyle)
      ? buttonStyle 
      : STYLES[0];      

      const [isLoading, setIsLoading] = useState(false);
      const [options, setOptions] = useState([]);
      const [golfterm, setGolfterm] = useState([]);

      const handleChange = (obj) => {      
        if (typeof obj[0] != "undefined") {          
          console.log(obj[0].title);
          const id = obj[0].id;    
          axios.get('https://golffakta-strapi.ettkontor.se/golfterms/'+id)
              .then(response => {       
                console.log(response.data);   
                setGolfterm(response.data.content);                
              })
              .catch(function (error) {
                console.log(error);                
                setGolfterm({
                    id:0,
                    title:'Sorry. Article not found'
                });    
              });     

          
        }
      }


      const handleSearch = (query) => {
        setIsLoading(true);
    
        //fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
        //fetch(`${SEARCH_URI}?_q=${query}`)
        fetch(`${SEARCH_URI}?title_contains=${query}`)
          .then((resp) => resp.json())
          .then((resp) => {
            const options = resp.map((i) => ({
              title: i.title,
              id: i.id,                          
            }));
    
            setOptions(options);
            setIsLoading(false);
          });
      };
      const filterBy = () => true;
    
      return (
        <>
        <div className="golftermsearchcontainer">
        <AsyncTypeahead
          filterBy={filterBy}
          id="golftermid"
          isLoading={isLoading}
          labelKey="title"
          minLength={3}
          className='hero-search-input'
          onSearch={handleSearch}
          onChange={handleChange}
          options={options}
          placeholder="Sök..."
          renderMenuItemChildren={(option, props) => (
            <Fragment>           
              <span>{option.title}</span>
            </Fragment>
          )}
        />
        <Link to ='/golfterms' className='hero-link-search'>
            <button
            className = 'hero-btn-serch'
            onClick = {onClick}
            type = {type}
            >
                <img className='hero-btn-serch-image' src={search_icon} />
            </button>            
        </Link>
        <br />
        </div>
        <div className='input-areas'>    
        <div className='golfterm'>   
          {golfterm}
          </div>      
        </div>
        </>  
      );
 };
