import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import axios from 'axios';
import MarkdownView from 'react-showdown';
import '../../assets/css/App.css';
import '../../assets/css/Infopage.css';

export default function Infopages() {
    const [infopage, setInfopage] = useState([]);
    const { id } = useParams();    

    const getInfopage = async () => {
        try {    
            console.log(id);
            axios.get('https://golffakta-strapi.ettkontor.se/info-pages/'+id)
              .then(response => {       
                //console.log(response.data);    
                setInfopage(response.data);                
              })
              .catch(function (error) {
                console.log(error);
                setInfopage({
                    id:0,
                    title:'Sorry. infopage not found'
                });    
              });       
        } catch (error) {        
          this.setState({ error, loading: false });
        }  
    }
    
    useEffect(()=> {        
        getInfopage();            
    },[id]);

    return (
        <>
      <div className="infopage">              
            <div className="infopage__container">
                <div className="infopage__wrapper">   
                    <div className="infopage__content">                        
                        <h1>{infopage.title}</h1>
                        <div className="infopagecontent">
                        <MarkdownView
                            markdown={infopage.content}
                            options={{ tables: true, emoji: true, simpleLineBreaks: true }}
                        />                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}