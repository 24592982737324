import React, {useState, useEffect} from 'react';
import CardItem from './CardItem';
import '../assets/css/Cards.css';
import axios from 'axios';

function Cards() {
    const [puffar, setPuffar] = useState([]);

    const getPuffar = () => {
        try {    
            axios.get('https://golffakta-strapi.ettkontor.se/homepage')
              .then(response => {                       
                setPuffar(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });       
        } catch (error) {        
          this.setState({ error, loading: false });
        }  
    }

    useEffect(()=> {
        getPuffar();
    },[])

    return (        
        <div className="cards">                      
            <div className="cards__container">
                <div className="cards__wrapper">                        
                    <ul className="cards__items">                        
                        {puffar.map((puff, index) => (
                               <CardItem 
                               key={index}
                               src= {"https://golffakta-strapi.ettkontor.se/" + puff.image}
                               text= {puff.content}
                               label={puff.title}
                               path={puff.url} />                               
                        ))}                        
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards;
