import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import axios from 'axios';
import MarkdownView from 'react-showdown';
import '../../assets/css/App.css';
import '../../assets/css/Article.css';

export default function Articles() {
    const [article, setArticle] = useState([]);
    const { id } = useParams();    

    const getArticle = async () => {
        try {    
            //console.log(id);
            axios.get('https://golffakta-strapi.ettkontor.se/articles/'+id)
              .then(response => {       
                //console.log(response.data);    
                setArticle(response.data);                
              })
              .catch(function (error) {
                console.log(error);
                setArticle({
                    id:0,
                    title:'Sorry. Article not found'
                });    
              });       
        } catch (error) {        
          this.setState({ error, loading: false });
        }  
    }
    
    useEffect(()=> {        
        getArticle();                
    },[id]);

    return (
        <>
      <div className="article">              
            <div className="article__container">
                <div className="article__wrapper">   
                    <div className="article__content">                        
                        <h1>{article.title}</h1>
                        <div className="articlecontent">
                        <MarkdownView
                            markdown={article.content}
                            options={{ 
                                backslashEscapesHTMLTags: true,
                                parseImgDimensions: true,
                                simpleLineBreaks: true,
                                simplifiedAutoLink: true,
                                smoothLivePreview: true,
                                splitAdjacentBlockquotes: false,
                                strikethrough: true,
                                tables: true,
                                tasklists: true,
                                underline: true,
                                emoji: true
                            }}
                        />                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}