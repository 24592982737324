import React from 'react';
import '../assets/css/Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--light', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
    children, 
    type, 
    onClick, 
    buttonStyle, 
    buttonSize
}) => {
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
    
    const checkButtonStyle = STYLES.includes(buttonStyle)
      ? buttonStyle 
      : STYLES[0];      

      return (
          <>        
        <Link to ='/golfterms' className='btn-mobile'>
            <button
            className = {` ${checkButtonStyle} ${checkButtonSize}`}
            onClick = {onClick}
            type = {type}
            >
                {children}
            </button>
        </Link>
        </>
      )
 };